import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/login-page.jpg';
import theme from '../../styles';
import PrimaryButton from '../../components/PrimaryButton';
import Navbar from '../../components/Navbar';

const Intern = ({ props }) => {

  const handleSystemClick = () => {
    window.open('https://arcimex.admintotal.com/admin/login/?next=/admin/index/', '_blank');
  };
  const handleWebMailClick = () => {
    window.open('http://arcimex.com/webmail', '_blank');
  };

  return (
    <MainContainer>
      <Navbar position='fixed' />
      <ImageContainer>
        <H1>BIENVENIDO</H1>
      </ImageContainer>
      <LeftContainer>
        <TextContainer>
          <H2>Interno</H2>
          <PrimaryButton onClick={handleSystemClick} backgroundColor={theme.color.cream} color={theme.color.textPrimary} padding='15px 50px' width='100%'>SISTEMA</PrimaryButton>
          <PrimaryButton onClick={handleWebMailClick} border='1px solid white' padding='15px 50px' width='100%'>WEBMAIL</PrimaryButton>
        </TextContainer>
      </LeftContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  height: '100svh', // Adjust height as needed
  backgroundColor: theme.color.brick,
}));

const ImageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  position: 'relative',
  padding: 50,
  boxSizing: 'border-box',
  width: '50%',
  height: '100svh', // Adjust height as needed
  backgroundColor: theme.color.brick,
  backgroundImage: `url(${MainHeroBackgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  // padding: 100,
  gap: 50,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity black
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const LeftContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  width: '50%',
  height: '100svh', // Adjust height as needed
  backgroundColor: theme.color.brick,
  gap: 30,
}));

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  width: 'auto',
  gap: 30,
}));

const H1 = styled('h1')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 60,
  textAlign: 'center',
  margin: 0,
}));

const H2 = styled('h1')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '500',
  fontSize: 24,
  textAlign: 'center',
  margin: 0,
}));

export default Intern;