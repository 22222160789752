import { styled } from '@mui/material';
import theme from '../../styles';
import Hyperlink from '../../components/Hyperlink';
import ProductCard from '../../components/ProductCard';
import ProductsData from '../../data/products';

const HighlightedProducts = ({ props }) => {
  const MainContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    height: 'auto',
    backgroundColor: '#FFEEDF',
    margin: 20,
    padding: '50px 30px',
    gap: 50,
  }));

  const ProductContainer = styled('div')(() => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 15,
  }));

  const HeaderContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }));

  const H1 = styled('h1')(() => ({
    color: 'black',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '700',
    fontSize: 30,
    margin: 0,
  }));

  return (
    <MainContainer>
      <HeaderContainer>
        <H1>Productos destacados</H1>
        <Hyperlink destination='/products'>Ver todo</Hyperlink>
      </HeaderContainer>
      <ProductContainer>
        <ProductCard item={ProductsData[2]} />
        <ProductCard item={ProductsData[7]} />
        <ProductCard item={ProductsData[12]} />

      </ProductContainer>
    </MainContainer>
  );
};

export default HighlightedProducts;
