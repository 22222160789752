import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import NoMatch from '../pages/NoMatch';
import Product from '../pages/Product';
import Products from '../pages/Products';
import Login from '../pages/Login';
import Footer from '../sections/Footer';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyNotice from '../pages/PrivacyNotice';
import Intern from '../pages/Intern'

const Router = (props) => {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path='home' element={<Home />} />
        <Route path='/product/:url' element={<Product />} />
        <Route path='/products' element={<Products />} />
        <Route path='/products/:category' element={<Products />} />
        <Route path='/login' element={<Login />} />
        <Route path='/interno' element={<Intern />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-notice' element={<PrivacyNotice />} />
        <Route path='*' element={<NoMatch />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
