import Navbar from '../../components/Navbar'
import NoMatchSection from '../../sections/NoMatchSection';

function NoMatch() {
  return (
    <>
      <Navbar position='fixed' />
      <NoMatchSection />
    </>
  );
}

export default NoMatch;
