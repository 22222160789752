import React from 'react'
import { styled } from '@mui/material';
import theme from '../../styles';
import ArcimexLogotype from '../../assets/arcimex-logotype-colored.svg'
import Hyperlink from '../../components/Hyperlink'

const Footer = ({ children }) => {

  return (
    <MainContainer>
      <Hyperlink destination='/'>
        <Logo src={ArcimexLogotype} alt='Arcimex Logotype' />
      </Hyperlink>
      <ItemsContainer>
        <MenuItem href='/privacy-notice' >
          Aviso de privacidad
        </MenuItem>
        <MenuItem href='/terms-and-conditions' >
          Términos y condiciones
        </MenuItem>
      </ItemsContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.color.light,
  padding: '20px'
}));
const Logo = styled('img')(() => ({
  width: '150px',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));
const ItemsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  padding: '20px',
}));
const MenuItem = styled('a')(() => ({
  color: theme.color.textPrimary,
  textDecoration: 'none',
  transition: 'transform 0.3s ease-out',
  padding: 20,
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

export default Footer
