import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import theme from '../../styles';
import ProductsData from '../../data/products';
import Navbar from '../../components/Navbar';
import ContactSection from '../../sections/Contact'
import BrandOffer from '../../sections/BrandOffer'
import ComplementProducts from '../../sections/ComplementProducts';

const ProductPage = () => {
  const { url } = useParams();

  // Fetch the product data based on the URL parameter
  const product = ProductsData.find(item => item.url === url);

  if (!product) {
    return <MainContainer>Product not found</MainContainer>;
  }

  return (
    <MainContainer>
      <Navbar position='sticky' customtextcolor={theme.color.textPrimary} searchBarBackgroundColor={theme.color.beige}/>
      <ProductContainer>
        <Image src={product.image} alt={product.name} backgroundColor={product.backgroundColor} />
        <TextContainer>
          <H1>{product.name}</H1>
          <H2>Ficha técnica</H2>
          <OrangeLineDivider />
          <QualityRow>
            <Quality>
              <Key>Ancho:</Key>
              <Value>{product.width}</Value>
            </Quality>
            <Quality>
              <Key>Piezas por m2:</Key>
              <Value>{product.pieces}</Value>
            </Quality>
            <Quality>
              <Key>Espesor:</Key>
              <Value>{product.thickness}</Value>
            </Quality>
          </QualityRow>
          <OrangeLineDivider />
          <QualityRow>
            <Quality>
              <Key>Largo:</Key>
              <Value>{product.length}</Value>
            </Quality>
            <Quality>
              <Key>Peso:</Key>
              <Value>{product.weight}</Value>
            </Quality>
          </QualityRow>
          <OrangeLineDivider />
          <Category>Categoría: {product.category}</Category>
        </TextContainer>
      </ProductContainer>
      <BrandOffer/>
      <ContactSection/>
      <ComplementProducts/>
    </MainContainer>
  );
};


const MainContainer = styled('div')(() => ({
  color: theme.color.dark,
  backgroundColor: theme.color.light,
  fontSize: '20px',
  fontFamily: theme.fontFamily.primary,
}));
const ProductContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 100,
  gap: 50,
}));
const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
}));
const QualityRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
}));
const Image = styled('img')(({ backgroundColor }) => ({
  width: '30%',
  maxWidth: '1000px',
  backgroundColor: backgroundColor || null,
}));
const H1 = styled('h1')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 25,
  margin: 0,
}));
const H2 = styled('h2')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 20,
  margin: 0,
}));
const Category = styled('p')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '400',
  fontSize: 14,
  margin: 0,
}));
const Key = styled('p')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '400',
  fontSize: 16,
  margin: 0,
}));
const Value = styled('p')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 16,
  margin: 0,
}));
const OrangeLineDivider = styled('div')(() => ({
  borderBottom: `1px solid ${theme.color.brick}`,
  width: '100%',
}));
const Quality = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export default ProductPage;
