import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import theme from '../../styles';
import Navbar from '../../components/Navbar';
import ProductCard from '../../components/ProductCard';
import ProductsData from '../../data/products';
import BannerImage from '../../assets/banner-products-page.png'
import CircularProgress from '@mui/material/CircularProgress';

const Products = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(category || 'All');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory('All');
    }
  }, [category]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      const filtered = selectedCategory === 'All' ? ProductsData : ProductsData.filter(product => product.category === selectedCategory);
      setFilteredProducts(filtered);
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, [selectedCategory]);

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setLoading(true);
    setSelectedCategory(newCategory);
    navigate(`/products/${newCategory !== 'All' ? newCategory : ''}`);
  };

  const categories = ['All', ...new Set(ProductsData.map(product => product.category))];

  return (
    <>
      <Navbar position='sticky' customtextcolor={theme.color.textPrimary} searchBarBackgroundColor={theme.color.beige} />
      <MainContainer>
        <Banner src={BannerImage} alt='banner for products' />
        <HeaderContainer>
          <H1>Todos los productos</H1>
          <Select value={selectedCategory} onChange={handleCategoryChange}>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </Select>
          <LineDivider />
          <p>Mostrando {filteredProducts.length} {filteredProducts.length === 1 ? 'resultado' : 'resultados'}</p>
        </HeaderContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <ProductContainer>
            {filteredProducts.map((item, index) => (
              <ProductCard item={item} key={index} />
            ))}
          </ProductContainer>
        )}
      </MainContainer>
    </>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  height: 'auto',
  backgroundColor: theme.color.light,
  margin: 40,
  gap: 50,
}));

const ProductContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: 15,
  gridAutoRows: 'minmax(200px, auto)', // Ensure rows have a minimum height and grow dynamically
}));

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
}));

const LineDivider = styled('div')(() => ({
  width: '100%',
  borderBottom: `2px solid #C9694A`,
}));

const H1 = styled('h1')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '400',
  fontSize: 30,
  margin: 0,
}));
const Banner = styled('img')({
  width: '100%',
});
const Select = styled('select')({
  border: 'none',
  fontSize: 16,
});

export default Products;
