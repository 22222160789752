import React, { useState } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProductsData from '../../data/products';
import theme from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchBarBackgroundColor }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 0) {
      const filteredSuggestions = ProductsData.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (url) => {
    navigate(`/product/${url}`);
    setQuery('');
    setSuggestions([]);
  };

  return (
    <SearchBarContainer>
      <InputContainer searchBarBackgroundColor={searchBarBackgroundColor}>
        <Input type="text"
          placeholder="Buscar productos..."
          value={query}
          onChange={handleInputChange}
          searchBarBackgroundColor={searchBarBackgroundColor} />
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </InputContainer>
      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion.url)}>
              {suggestion.name}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </SearchBarContainer>
  );
};

const SearchBarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '200px',
});

const InputContainer = styled('div')(({ searchBarBackgroundColor }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  border: '1px solid #fff',
  backgroundColor: searchBarBackgroundColor || 'rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(5px)',
  // boxShadow: position === 'sticky' ? 'none' : scrollPosition !== 0 ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
  borderRadius: 50,
  fontSize: '16px',
  outline: 'none',
  color: searchBarBackgroundColor ? theme.color.textPrimary : 'white'
}));

const Input = styled('input')(({ searchBarBackgroundColor }) => ({
  width: '100%',
  fontSize: '14px',
  outline: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  color: searchBarBackgroundColor ? theme.color.textPrimary : 'white',
  '::placeholder': {
    color: searchBarBackgroundColor ? theme.color.textPrimary : 'white',
  },
}));

const SuggestionsList = styled('ul')({
  position: 'absolute',
  top: '40px',
  width: '100%',
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#fff',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  zIndex: 1000,
});

const SuggestionItem = styled('li')({
  fontFamily: theme.fontFamily.primary,
  color: 'black',
  padding: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.color.brick,
    color: theme.color.light,
  },
});

export default SearchBar;
