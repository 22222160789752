import { styled } from '@mui/material';
import theme from '../../styles';
import AboutUsImage from '../../assets/seccion-nosotros.png'
import PrimaryButton from '../../components/PrimaryButton'
import Category1 from '../../assets/category-1.png'
import Category2 from '../../assets/category-2.png'
import Category3 from '../../assets/category-3.png'
import Category4 from '../../assets/category-4.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const CategoriesSection = ({ props }) => {

  return (
    <MainContainer>
      <TextContainer>
        <H1>Conoce
          <br />
          nuestras
          <br />
          categorías</H1>
        <PrimaryButton destination='/products' backgroundColor={theme.color.brown}>Ir a categorías</PrimaryButton>
        <NavLink to='/products/Celosias'>
          <OuterContainer1>
            <Image1Container />
            <H2>Celosia <FontAwesomeIcon icon={faChevronRight} size='l' /></H2>
          </OuterContainer1>
        </NavLink>
        <NavLink to='/products/Tejas'>
          <OuterContainer2>
            <Image2Container />
            <H2>Teja <FontAwesomeIcon icon={faChevronRight} size='l' /></H2>
          </OuterContainer2>
        </NavLink>
        <NavLink to='/products/Losetas'>
          <OuterContainer3>
            <Image3Container />
            <H2>Loseta <FontAwesomeIcon icon={faChevronRight} size='l' /></H2>
          </OuterContainer3>
        </NavLink>
        <NavLink to='/products/Fachaletas'>
          <OuterContainer4>
            <Image4Container />
            <H2>Fachaleta <FontAwesomeIcon icon={faChevronRight} size='l' /></H2>
          </OuterContainer4>
        </NavLink>
      </TextContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  position: 'relative',
  boxSizing: 'border-box',
  padding: 100,
  gap: 100,
}));
const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 40,
  paddingLeft: '5%',
}));
const H1 = styled('h1')(() => ({
  color: theme.color.textDark,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 40,
  margin: 0,
}));
const H2 = styled('h2')(() => ({
  color: theme.color.light,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 30,
  margin: 0,
  padding: 30,
  marginTop: '-90px'
}));
const OuterContainer1 = styled('div')({
  width: '25vw',
  maxWidth: '800px',
  position: 'absolute',
  top: '5%',
  right: '19%',
});
const Image1Container = styled('div')({
  width: '100%',
  height: '0',
  paddingBottom: '100%', // Aspect ratio 1:1
  backgroundImage: `url(${Category1})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});
const OuterContainer2 = styled('div')({
  width: '18vw',
  maxWidth: '800px',
  position: 'absolute',
  top: '23%',
  right: '8%',
});
const Image2Container = styled('div')({
  width: '100%',
  height: '0',
  paddingBottom: '150%', // Aspect ratio 2:3
  backgroundImage: `url(${Category2})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});
const OuterContainer3 = styled('div')({
  width: '22vw',
  maxWidth: '800px',
  position: 'absolute',
  top: '45%',
  left: '10%',
});
const Image3Container = styled('div')({
  width: '100%',
  height: '0',
  paddingBottom: '100%', // Aspect ratio 1:1
  backgroundImage: `url(${Category3})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});
const OuterContainer4 = styled('div')({
  width: '25vw',
  maxWidth: '800px',
  position: 'absolute',
  top: '60%',
  left: '28%',
});
const Image4Container = styled('div')({
  width: '100%',
  height: '0',
  paddingBottom: '66%', // Aspect ratio 1:1
  backgroundImage: `url(${Category4})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});

export default CategoriesSection;
