import './App.css';
import CookiesConsent from './components/CookiesConsent';
import { ScrollProvider } from './components/ScrollContext';
import Router from './routes/router';

function App() {
  return (
    <>
      <ScrollProvider>
        <Router></Router>
        <CookiesConsent />
      </ScrollProvider>
    </>
  );
}

export default App;
