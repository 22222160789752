import React, { useState, useEffect } from 'react';
import WhiteLogo from '../../assets/arcimex-logotype-white.svg';
import ColorLogo from '../../assets/arcimex-logotype-colored.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useScroll } from '../ScrollContext';
import { styled } from '@mui/material';
import theme from '../../styles';
import SmallButton from '../SmallButton'
import SearchBar from '../SearchBar';

const Navbar = ({ customtextcolor, position, searchBarBackgroundColor }) => {
  const { contact, aboutUs } = useScroll();
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash === '#contacto') {
      scrollToSection(contact);
    }
  }, [location, contact]);

  useEffect(() => {
    if (location.hash === '#about-us') {
      scrollToSection(aboutUs);
    }
  }, [location, aboutUs]);

  const scrollToSection = (sectionRef) => {
    window.scrollTo({
      top: sectionRef.current.offsetTop - 73,
      behavior: 'smooth',
    });
  };

  const handleNavClick = (sectionRef, url) => {
    if (location.pathname !== '/' && location.pathname !== '/home') {
      navigate(url);
    } else {
      scrollToSection(sectionRef);
    }
  };

  return (
    <NavbarContainer scrollPosition={scrollPosition} position={position}>
      <LogoContainer>
        <NavLink to='/home'>
          <Logo src={customtextcolor ? ColorLogo : WhiteLogo} alt='Logo de Arcimex' />
        </NavLink>
      </LogoContainer>
      <LinksContainer>
        <NavLinkStyled to='/home' className='nav-link' customtextcolor={customtextcolor}>
          Inicio
        </NavLinkStyled>
        <NavLinkStyled to='/products' className='nav-link' customtextcolor={customtextcolor}>
          Productos
        </NavLinkStyled>
        <NavLinkStyled
          to='/#about-us'
          customtextcolor={customtextcolor}
          onClick={(e) => {
            e.preventDefault();
            handleNavClick(aboutUs, '/#about-us');
          }}
        >
          Nosotros
        </NavLinkStyled>
        <NavLinkStyled
          to='/#contacto'
          customtextcolor={customtextcolor}
          onClick={(e) => {
            e.preventDefault();
            handleNavClick(contact, '/#contacto');
          }}
        >
          Contáctanos
        </NavLinkStyled>
        <SearchBar searchBarBackgroundColor={searchBarBackgroundColor}></SearchBar>
      </LinksContainer>
      <LogoContainer>
        {['/login'].includes(location.pathname) ? null : (<NavLink to='/login'>
          {/* <Logo src={logo} alt="Logo de Arcimex" /> */}
          <SmallButton>Iniciar sesión</SmallButton>
        </NavLink>)}

      </LogoContainer>
    </NavbarContainer >
  );
};

// Styled component using Material-UI's styled function
const NavbarContainer = styled('div')(({ scrollPosition, position }) => ({
  position: position || 'fixed',
  top: 0,
  zIndex: 50,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '20px 100px',
  transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
  backgroundColor:
    position === 'sticky' ? theme.color.light : scrollPosition !== 0
      ? 'rgba(190, 86, 33, 0.6)'
      : 'transparent',
  backdropFilter: position === 'sticky' || scrollPosition !== 0 ? 'blur(10px)' : 'none',
  boxShadow: position === 'sticky' ? 'none' : scrollPosition !== 0 ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',

  fontFamily: theme.fontFamily.secondary,
  color: theme.color.light,
  borderBottom: position === 'sticky' ? '1px solid #F2C1A6' : null,
}));

const LogoContainer = styled('div')({
  flexShrink: 0,
  flexBasis: '180px',
});

const Logo = styled('img')({
  width: '110px',
});

const LinksContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  gap: '10px',
});

const NavLinkStyled = styled(NavLink)(({ customtextcolor }) => ({
  color: customtextcolor || theme.color.light,
  fontFamily: theme.fontFamily.primary,
  textDecoration: 'none',
  padding: '20px 20px',
  fontSize: '20px',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    color: customtextcolor ? theme.color.brick : theme.color.highlight,
    transform: 'translateY(-10px)',
  },
}));


export default Navbar;
