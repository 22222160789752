import { styled } from '@mui/material';
import theme from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Reviews = ({ props }) => {

  return (
    <MainContainer>

      <H3>Opiniones de nuestros clientes</H3>
      <H1>“Calidad impresionante, gran atención al cliente y tiempos perfectos.”</H1>
      <Stars>
        <FontAwesomeIcon icon={faStar} size='xl' color={theme.fontFamily.primary} />
        <FontAwesomeIcon icon={faStar} size='xl' color={theme.fontFamily.primary} />
        <FontAwesomeIcon icon={faStar} size='xl' color={theme.fontFamily.primary} />
        <FontAwesomeIcon icon={faStar} size='xl' color={theme.fontFamily.primary} />
        <FontAwesomeIcon icon={faStar} size='xl' color={theme.fontFamily.primary} />
      </Stars>
      <Line />
      <H2>Lic. Raul Lopez</H2>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  height: 'auto',
  backgroundColor: theme.color.beige,
  margin: 50,
  padding: '50px 30px',
  gap: 50,
}));

const Stars = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
}));

const H1 = styled('h1')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '600',
  fontSize: 30,
  margin: 0,
}));
const H2 = styled('h3')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 20,
  margin: 0,
}));
const H3 = styled('h2')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 18,
  margin: 0,
}));
const Line = styled('div')(() => ({
  width: '350px',
  borderBottom: `1px solid ${theme.color.brick}`,
}));

export default Reviews;
