import React, { useState, useEffect, forwardRef } from 'react';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import { useScroll } from '../../components/ScrollContext'
import Swal from 'sweetalert2';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material';
import theme from '../../styles';
import CustomButton from '../../components/CustomButton';

const ContactSection = forwardRef((props, ref) => {
  const { contact } = useScroll();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  });
  const [recaptcha, setRecaptcha] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    console.log('sending')
    e.preventDefault();

    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAIL_USER_ID;

    if (!recaptcha) {
      console.log('Recaptcha failed');
      return;
    }

    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          category: '',
          name: '',
          email: '',
          company: '',
          message: '',
        });
        Swal.fire('¡Éxito!', 'Email enviado correctamente', 'success');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  if (!reCaptchaKey) {
    console.error("reCaptcha key is not defined");
    return null; // or render some fallback UI
  }

  const inputStyle = {
    width: '80%',
    backgroundColor: 'white',
    margin: 10,
    borderRadius: '10px',
  };

  return (
    <MainContainer onSubmit={handleSubmit} ref={contact}>
      <HeaderContainer>
        <H1>Contáctanos</H1>
      </HeaderContainer>
      <TextInputContainer>
        <CustomTextField
          label='Nombre'
          variant='filled'
          type='text'
          id='name'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Correo'
          variant='filled'
          type='email'
          id='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Empresa'
          variant='filled'
          id='company'
          name='company'
          value={formData.company}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Mensaje'
          variant='filled'
          id='message'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          multiline
          maxRows={4}
          style={inputStyle}
        />
        <ButtonContainer>
          <CustomButton
            variant='contained'
            type='submit'
            width='80%'
            marginTop='10px'
            backgroundColor={theme.color.accent}
            color={theme.color.textPrimary}
          >
            Enviar
          </CustomButton>
        </ButtonContainer>
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
        </GoogleReCaptchaProvider>
      </TextInputContainer>
    </MainContainer>
  );
});

const MainContainer = styled('form')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.color.beige,
  margin: 100,
  padding: '0 50px 50px',
  gap: 30,
  '@media (max-width: 600px)': {
    padding: '30px 10px',
  },
}));

const H1 = styled('h1')(() => ({
  color: theme.color.textDark,
  fontSize: '50px',
  fontWeight: '500',
}));

const HeaderContainer = styled('div')(() => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0 0 0 50px',
}));

const TextInputContainer = styled('div')({
  width: '50%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 40,
  gap: 10,
  backgroundColor: theme.color.cream,
});

const ButtonContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '10px 60px',
}));

const CustomTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    width: '100%',
    backgroundColor: theme.color.cream,
    transition: 'box-shadow 0.3s ease-in-out',
  },
  '& .MuiFilledInput-root:focus-within, & .MuiFilledInput-root.Mui-focused': {},
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    borderBottom: `2px solid ${theme.color.brick}`,
  },
  '& .MuiFilledInput-root:hover': {
    borderBottom: `2px solid ${theme.color.brick}`,
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInputLabel-root': {
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.color.textPrimary,
  },
  '& .MuiInputLabel-asterisk': {
    display: 'none',
  },
});

export default ContactSection;
