import { styled } from '@mui/material';
import theme from '../../styles';
import { useNavigate } from 'react-router-dom';

const Hyperlink = (props) => {
  const navigate = useNavigate();
  
  const Button = styled('button')(() => ({
    width: 'auto',
    borderRadius: 50,
    border: 'none',
    color: theme.color.textPrimary,
    backgroundColor: 'transparent',
    fontSize: '16px',
    fontWeight: '500',
    textDecoration: 'underline',
    fontFamily: theme.fontFamily.primary,
    transition: 'transform 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  const handleNavigate = (destination) => {
    navigate(destination);
  };

  return (
    <Button onClick={() => handleNavigate(props.destination)}>
      {props.children}
    </Button>
  );
};

export default Hyperlink;
