export const PrivacyNoticeContent = {
  Title: 'Aviso de Privacidad',
  Content: `
    Aviso de Privacidad Arcimex: 100% barro natural. En Arcimex, nos comprometemos a proteger y respetar tu privacidad. Este aviso de privacidad explica cómo recopilamos, usamos y protegemos tu información personal cuando utilizas nuestro sitio web.

    Información que Recopilamos
    Podemos recopilar y procesar los siguientes datos sobre ti: 
    - Información de Identificación Personal: Nombre, dirección de correo electrónico, número de teléfono y cualquier otra información que proporciones al registrarte en nuestro sitio.
    - Datos de Navegación: Información sobre tu visita, incluyendo las páginas que visitas, el tiempo que pasas en ellas, el sistema operativo y el navegador que utilizas.
    - Cookies: Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies son pequeños archivos de datos que se almacenan en tu dispositivo.

    Uso de la Información
    Utilizamos la información recopilada para los siguientes fines: 
    - Prestación de Servicios: Para proporcionarte información, productos o servicios que nos solicites o que consideremos que pueden interesarte.
    - Mejoras del Sitio Web: Para asegurar que el contenido de nuestro sitio se presenta de la manera más efectiva para ti y tu dispositivo.
    - Comunicación: Para enviarte notificaciones relacionadas con nuestros servicios, cambios en nuestros términos de servicio o políticas de privacidad, y otra información administrativa.

    Compartición de Información
    No vendemos, intercambiamos ni transferimos a terceros tu información personal identificable. Esto no incluye a terceros de confianza que nos asisten en operar nuestro sitio web, llevar a cabo nuestro negocio o prestarte servicios, siempre y cuando esas partes acuerden mantener esta información confidencial.

    Seguridad de la Información
    Implementamos una variedad de medidas de seguridad para mantener la seguridad de tu información personal. Sin embargo, ten en cuenta que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro.

    Tus Derechos
    Tienes derecho a acceder, rectificar o borrar cualquier información personal que tengamos sobre ti. Si deseas ejercer estos derechos, por favor contáctanos a [contacto@arcimex.com].

    Cambios a Nuestro Aviso de Privacidad
    Podemos actualizar nuestro aviso de privacidad de vez en cuando. Te notificaremos cualquier cambio publicando el nuevo aviso de privacidad en esta página.

    Contacto
    Si tienes alguna pregunta sobre este aviso de privacidad, por favor contáctanos a [contacto@arcimex.com].
  `
};
