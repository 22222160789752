import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import { NavLink } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';

const CookiesConsent = (props) => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookies-consent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookies-consent', 'accepted');
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  const MainContainer = styled('div')(() => ({
    position: 'sticky',
    bottom: 0,
    width: '100%',
    padding: 30,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(190, 86, 33, 0.6)',
    zIndex: 100,
  }));
  const H1 = styled('h1')(() => ({
    color: theme.color.light,
    fontFamily: theme.fontFamily.primary,
    fontSize: 20,
  }));
  const NavLinkCustom = styled(NavLink)(() => ({
    color: 'white',
    textDecoration: 'none',
  }));
  const Agreement = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  }));
  const Links = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 70,
  }));

  return (
    <MainContainer>
      <Agreement>
        <H1>Usamos cookies para mejorar tu experiencia</H1>
        <PrimaryButton onClick={handleAccept} backgroundColor={theme.color.brown} padding={'10px 20px'} fontSize='14px'>Aceptar cookies</PrimaryButton>
      </Agreement>
      <Links>
        <NavLinkCustom to='/privacy-notice'>Aviso de privacidad</NavLinkCustom>
        <NavLinkCustom to='/terms-and-conditions'>Términos y condiciones</NavLinkCustom>
      </Links>
    </MainContainer>
  );
};

export default CookiesConsent;
