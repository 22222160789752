import { styled } from '@mui/material';
import theme from '../../styles';
import { useScroll } from '../../components/ScrollContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBars, faShield } from '@fortawesome/free-solid-svg-icons';
import AboutUsImage from '../../assets/seccion-nosotros.png'

const AboutUs = ({ props }) => {
  const { aboutUs } = useScroll();

  return (
    <MainContainer ref={aboutUs}>
      <LeftContainer>
        <Banner src={AboutUsImage} alt='banner for about us' />
      </LeftContainer>
      <RightContainer>
        <TextContainer>
          <H2>Nosotros | Por qué escoger Arcimex</H2>
          <H1>
            15 años haciendo historia en México
          </H1>
        </TextContainer>
        <PilarsContainer>
          <Pilar>
            <FontAwesomeIcon icon={faCheck} color='white' size='xl' />
            <H3>Calidad</H3>
            <Paragraph>Son productos completamente cocidos que no requieren protección ni recubrimiento alguno. Garantizando tonos uniformes del barro natural.</Paragraph>
          </Pilar>
          <VerticalLine />
          <Pilar>
            <FontAwesomeIcon icon={faBars} color='white' size='xl' />
            <H3>Variedad</H3>
            <Paragraph>Amplia gama de modelos de tejas medias cañas, de ala, árabes o cónicas y planas. Brinda a su construcción protección, decoración y valor.</Paragraph>
          </Pilar>
          <VerticalLine />
          <Pilar>
            <FontAwesomeIcon icon={faShield} color='white' size='xl' />
            <H3>Durabilidad</H3>
            <Paragraph>Amplia gama de modelos de tejas medias cañas, de ala, árabes o cónicas y planas. Brinda a su construcción protección, decoración y valor.</Paragraph>
          </Pilar>
        </PilarsContainer>
      </RightContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  backgroundColor: theme.color.brick,
  padding: 100,
  gap: 100,
}));
const LeftContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
}));
const RightContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
  boxSizing: 'border-box',
  backgroundColor: theme.color.brick,
  gap: 100,
}));
const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 20,
}));
const PilarsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
}));
const Pilar = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 30,

}));
const H1 = styled('h1')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 40,
  textAlign: 'center',
  margin: 0,
}));
const H2 = styled('h2')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '600',
  fontSize: 25,
  textAlign: 'center',
  margin: 0,
}));
const H3 = styled('h3')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 30,
  textAlign: 'center',
  margin: 0,
  padding: 0,
}));
const Line = styled('div')(() => ({
  width: '150px',
  borderBottom: '1px solid white',
}));
const VerticalLine = styled('div')(() => ({
  borderRight: '1px solid white', flex: 1, flexGrow: 1, alignSelf: 'stretch'
}));
const Paragraph = styled('p')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '400',
  fontSize: 16,
  padding: 0,
  margin: 0,
}));
const Banner = styled('img')({
  width: '30vw',
  maxWidth: '800px'
});

export default AboutUs;
