import React, { useEffect } from 'react';
import { TermsAndConditionsContent } from './termsAndConditionsContent';
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';
import Navbar from '../../components/Navbar';
import theme from '../../styles';

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Términos y condiciones - NICDET';
  }, []);

  return (
    <MainContainer>
      <Navbar
        position='sticky'
        customtextcolor={theme.color.textPrimary}
        searchBarBackgroundColor={theme.color.beige}
      />
      <LegalContainer>
        <h2>{TermsAndConditionsContent.Title}</h2>
        <FormattedContent content={TermsAndConditionsContent.Content} />
      </LegalContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));
const LegalContainer = styled('div')(() => ({
  padding: 50,
}));

export default TermsAndConditions;
