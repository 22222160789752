import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/background-hero-section.jpg';
import theme from '../../styles';
import PrimaryButton from '../../components/PrimaryButton';

const NoMatchSection = ({ props }) => {

  return (
    <MainContainer>
      <TextContainer>
        <H2>Página no encontrada</H2>
        <H1>
          404
        </H1>
        <Paragraph>Algo ha desaparecido.
          <br />
          No pudimos encontrar la página que estás buscando.
        </Paragraph>
      </TextContainer>
      <PrimaryButton destination='/'>Regresar al inicio</PrimaryButton>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  height: '100svh', // Adjust height as needed
  backgroundImage: `url(${MainHeroBackgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  padding: 100,
  gap: 50,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity black
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));
const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const H1 = styled('h1')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 60,
  textTransform: 'uppercase',
  textAlign: 'center',
  margin: 0,
}));
const H2 = styled('h2')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 30,
  textTransform: 'uppercase',
  textAlign: 'center',
  margin: 0,
}));
const Paragraph = styled('p')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: '500',
  fontSize: 20,
  textAlign: 'center',
}));

export default NoMatchSection;
