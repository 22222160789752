import { styled } from '@mui/material';
import theme from '../../styles';
import { useNavigate } from 'react-router-dom';

const SmallButton = (props) => {
  const navigate = useNavigate();

  const Button = styled('button')(() => ({
    width: 'auto',
    padding: '10px 30px',
    borderRadius: 50,
    border: 'none',
    color: theme.color.light,
    backgroundColor: theme.color.brick,
    fontSize: '14px',
    fontWeight: '900',
    fontFamily: theme.fontFamily.primary,
    transition: 'transform 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  const handleNavigate = (destination) => {
    navigate(destination);
  };

  return (
    <Button
      type={props.type || null}
      onClick={
        props.destination ? () => handleNavigate(props.destination) : null
      }
    >
      {props.children}
    </Button>
  );
};

export default SmallButton;
