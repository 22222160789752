import React from 'react';
import { PrivacyNoticeContent } from './privacyNoticeContent';
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';
import Navbar from '../../components/Navbar';
import theme from '../../styles';

const PrivacyNotice = () => {
  return (
    <MainContainer>
      <Navbar
        position='sticky'
        customtextcolor={theme.color.textPrimary}
        searchBarBackgroundColor={theme.color.beige}
      />
      <LegalContainer>
        <h2>{PrivacyNoticeContent.Title}</h2>
        <FormattedContent content={PrivacyNoticeContent.Content} />
      </LegalContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));
const LegalContainer = styled('div')(() => ({
  padding: 50,
}));

export default PrivacyNotice;
